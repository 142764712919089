import React from "react"
import Layout from "../components/Layout"

import styled from "@emotion/styled"
import Divider from "../components/Divider"
import ArrowLink from "../components/ArrowLink"
import { networks } from "../data/networks"

const TextLink = styled.p`
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  padding-bottom: 0.1rem;
`

const FooterIcon = styled.a`
  display: flex;
  align-items: baseline;
  align-self: baseline;
  margin-top: 1.25rem;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  padding: 0;
  &:hover {
    box-shadow: inset 0 -2px 0 #000001;
  }
`

function SocialList({ name, url, icon }) {
  return (
    <FooterIcon href={url} target="_blank">
      <div>{icon}</div>
      <TextLink css={{ margin: "0 5px" }}>{name}</TextLink>
    </FooterIcon>
  )
}

export default function About() {
  return (
    <Layout noFooter={true}>
      <div>
        <div>
          <h2
            css={{
              fontSize: "2.5rem",
              fontWeight: 600,
              margin: 0,
              display: "block",
              width: "fit-content",
            }}
          >
            Où me trouver{" "}
            <span role="img" aria-label="link">
              👀
            </span>
            <div css={{ margin: "0.75rem 0" }}>
              <Divider />
            </div>
          </h2>
        </div>
        <div
          css={{
            display: "flex",
            alignContent: "flex-start",
            flexDirection: "column",
            marginBottom: "2rem",
          }}
        >
          {networks !== "undefined" &&
            networks.socialNetworks.map(network => {
              return (
                <SocialList
                  key={network.idx}
                  name={network.name}
                  url={network.url}
                  icon={network.icon}
                />
              )
            })}
        </div>
        <div css={{ marginTop: "3rem" }}>
          <ArrowLink isBackArrow to="/">
            Retour à l'accueil
          </ArrowLink>
        </div>
      </div>
    </Layout>
  )
}
